/* VARIABLES */
$color-purple: #6666cc;
$light-color-purple: #eeedf7;
$color-pink: #ff6699;
$light-color-pink: #ffeff5;
$color-red: #ff3333;
$light-color-red: #ffeced;
$color-green: #339966;
$light-color-green: #edf5f0;
$color-blue: #3399ff;
$light-color-blue: #ecf5ff;
$color-orange: #ff9933;
$light-color-orange: #fff5ed;
$color-yellow: #ffcc66;
$light-color-yellow: #fffaf0;