@use "@angular/material" as mat;

// @import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$taxgirl-primary: mat.define-palette(mat.$deep-purple-palette, 600, 800, 900);
$taxgirl-accent: mat.define-palette(mat.$grey-palette, 600, 400, 900);
$taxgirl-warn: mat.define-palette(mat.$red-palette, 700, 400, 900);

// Create the theme object (a Sass map containing all of the palettes).
$taxgirl-theme: mat.define-light-theme(
  $taxgirl-primary,
  $taxgirl-accent,
  $taxgirl-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($taxgirl-theme);
$primary: mat.get-color-from-palette($taxgirl-primary);
$accent: mat.get-color-from-palette($taxgirl-accent);
$warn: mat.get-color-from-palette($taxgirl-warn);

$taxgirl-grey: mat.define-palette(mat.$grey-palette, A100, A400, A700);
$taxgirl-bluegray: mat.define-palette(mat.$blue-grey-palette, 300, 600, 900);
