@import "./theme.scss";
@import "./card-boxes";
@import "./colors";

@mixin max-width($width) {
    @media screen and (max-width: $width) {
        @content;
    }
}
@mixin min-width($width) {
    @media screen and (min-width: $width) {
        @content;
    }
}
html {overflow-y: scroll;}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.purple {
    color: white;
    background-color: $color-purple;
}

.pink {
    color: $color-pink;
    background-color: $light-color-pink;
    border-bottom: 2px solid $color-pink;
}

.red {
    color: #ff3333;
    background-color: $light-color-red;
    border-bottom: 2px solid $color-red;
}

.green {
    color: #339966;
    background-color: $light-color-green;
    border-bottom: 2px solid $color-green;
}

.blue {
    color: #3399ff;
    background-color: $light-color-blue;
    border-bottom: 2px solid $color-blue;
}

.orange {
    color: #ff9933;
    background-color: $light-color-orange;
    border-bottom: 2px solid $color-orange;
}

.yellow {
    color: #ffcc66;
    background-color: $light-color-yellow;
    border-bottom: 2px solid $color-yellow;
}

/* STRUCTURE */
.logo {
    width: 100px;
}

.top-toolbar {
    position: fixed;
    top: 0px;
    z-index: 999;
}

.top-toolbar-row {
    width: 100%;
}

.top-toolbar-row .mat-title {
    margin-bottom: 0px;
}

.bottom-tabbar {
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 1;
    background-color: rgb(252, 252, 252);
    border-top: 1px solid $color-purple;
}

.bottom-tabbar-row {
    width: 100%;
}

.bottom-tabbar-button {
    color: $color-purple;
}

.bottom-tabbar .toolbar-icon {
    width: 24px;
    height: 24px;
}

.tabbar-tab-active {
    background-color: $light-color-purple;
}

/* SHAPES */
.fly-shape {
    position: absolute;
    width: 50vw;
    opacity: .10;
  
    &_right_bottom {
      width: 40vw;
      left: auto;
      right: 5vw;
      top: auto;
      bottom: 15vw;
    }

    &_center {
      width: 40vw;
      top: 30vw;
      left: 10%;
      right: auto;
    }
  
    > img {
      width: 100%;
      transform: rotate(-25deg);
    }
}

/* PAGE STYLES */
.appbar-icon {
    width: 24px;
    height: 24px;
}

.text-link {
    text-decoration: none;
    font-size: 12px;
    color: grey;
}

.bea {
    min-height: 160px;
    min-width: 25%;
}

.bea.presenting {
    background-image: url("/assets/images/bea-presenting_small.png");
    background-position: bottom left;
    background-size: contain;
    background-repeat: no-repeat;
}

.bea.thumbsup {
    background-image: url("/assets/images/bea-thumbsup.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.bea.welcome {
    background-image: url("/assets/images/bea-welcome.png");
    background-position: bottom left;
    background-size: contain;
    background-repeat: no-repeat;
}

.bea.hands-in-pocket {
    background-image: url("/assets/images/bea-hands-in-pocket.png");
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
}

.bea.smartphone {
    background-image: url("/assets/images/bea-smartphone.png");
    background-position: bottom left;
    background-size: contain;
    background-repeat: no-repeat;
}

.speech-bubble {
    position: relative;
    background: $color-purple;
    min-height: 60px;
    height: 100%;
    padding: 10px 16px;

}

.top.right.speech-bubble {
    border-radius: 8px 0px 8px 8px;
}

.top.left.speech-bubble {
    border-radius: 0px 8px 8px 8px;
}

.bottom.left.speech-bubble {
    border-radius: 8px 8px 8px 0px;
}

.bottom.right.speech-bubble {
    border-radius: 8px 8px 0px 8px;
}

.top.left.speech-bubble:after {
	content: "";
    position: absolute;
    top: 0;
    left: -20px;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-right-color: #6666cc;
    border-top: 0;
    border-left: 0;
}

.bottom.left.speech-bubble:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -20px;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom-color: #6666cc;
    border-top: 0;
    border-right: 0;
}

.top.right.speech-bubble:after {
    content: "";
    position: absolute;
    top: 0;
    right: -20px;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-left-color: #6666cc;
    border-top: 0;
    border-right: 0;
}

.bottom.right.speech-bubble:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: -20px;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-left-color: #6666cc;
    border-bottom: 0;
    border-right: 0;
}

.speech-bubble .bea-talks { 
    color: white;
    font-size: 22px;
}
.bea-bg {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 100%;
    height: 100%;
    background-image: url("/assets/images/bea-faust-opa40.png");
    background-position: bottom right;
    background-size: 20vh;
    background-repeat: no-repeat;
}

.bea-talks {
    font-family: Caveat, "Helvetica Neue", sans-serif;
    font-size: 24px;
}

.home.page-header {
    margin-top: 0px;
    background-image: url('/assets/images/home-header.jpg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    min-height: 320px;
    margin-bottom: 40px;
}

.page-header {
    margin-top: 100px;;
}

.page-header h1.mat-title,
.page-header p.mat-subtitle {
    margin: 0px;
}

.page-header .icon {
    width: 48px;
    height: 48px;
}

.container {
    padding: 16px 16px 120px 16px;
}

.save-button-area {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: stretch center;
    align-items: stretch;
    position: fixed;
    bottom: 56px;
    left: 0px;
    right: 0px;
}

.save-button-area button {
    border-radius: 0px;
    height: 50px;
}

.help-icon {
    cursor: pointer;
    color: $color-purple;
}

/* LOGIN/REGISTER */


.page {
    width: 100%;
    height: 100%;
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, rgb(126, 42, 120) 46%, $color-purple 100%);
}

.login-box {
    height: 600px;
    background-color: white;
    box-shadow: 0px 0px 20px #444;
}

.lounge {
    background-image: url('/assets/images/lounge.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.office {
    background-image: url('/assets/images/office.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bea-welcome-image {
    width: 200px;
}
.bea-sad-image {
    width: 180px;
}


@media screen and (max-width: 959px) {
    .login-container {
        width: 100%;
        height: 100%;
    }
    
    .login-box {
        height: 100%;
    }
    
    .bea-welcome-image {
        width: 150px;
        position: relative;
        left: 120px;
    }
    .bea-sad-image {
        width: 150px;
        position: relative;
        left: 120px;
    }

    .home.page-header {
        background-image: url(/assets/images/home-header.jpg);
        background-size: 80%;
        background-position: center right;
        background-repeat: no-repeat;
    }
}

@media screen and (max-width: 599px) {
    .bea-welcome-image {
        width: 60px;
        position: relative;
        left: 80px;
    }
    .bea-sad-image {
        width: 60px;
        position: relative;
        left: 80px;
        top: 20px;
    }
}

@media screen and (min-width: 960px) {
    .login-container {
        width: 1000px;
        height: 100%;
        margin: 0px auto;
    }

    .bea-welcome-image {
        position: relative;
        top: 50px;
    }
    .bea-sad-image {
        position: relative;
        top: 53px;
    }
}

/* MATERIAL OVERRIDES */
.mat-card {
    min-width: 400px !important;
}

.mat-step-label {
    width: 100%;
}

mat-label {
    font-size: 14px;
}

mat-vertical-stepper.stepper {
    margin-bottom: 32px;
}

.mat-dialog-content {
    overflow-y: hidden !important;
}

.mat-dialog-container {
    max-width: 600px;
    max-height: 300px;
}

.mat-dialog-container h1 {
    margin-top: 0px;
    font-size: 24px;
}

.mat-vertical-stepper-content[aria-expanded='false'] { 
    display: none !important; 
}

.mat-stepper-vertical.pink-icon .mat-step-header .mat-step-icon-selected, 
.mat-stepper-vertical.pink-icon .mat-step-header .mat-step-icon-state-edit {
    background-color: $color-pink;
}

.mat-stepper-vertical.red-icon .mat-step-header .mat-step-icon-selected, 
.mat-stepper-vertical.red-icon .mat-step-header .mat-step-icon-state-edit {
    background-color: $color-red;
}

.mat-stepper-vertical.green-icon .mat-step-header .mat-step-icon-selected, 
.mat-stepper-vertical.green-icon .mat-step-header .mat-step-icon-state-edit {
    background-color: $color-green;
}

.mat-stepper-vertical.blue-icon .mat-step-header .mat-step-icon-selected, 
.mat-stepper-vertical.blue-icon .mat-step-header .mat-step-icon-state-edit {
    background-color: $color-blue;
}

.mat-stepper-vertical.orange-icon .mat-step-header .mat-step-icon-selected, 
.mat-stepper-vertical.orange-icon .mat-step-header .mat-step-icon-state-edit {
    background-color: $color-orange;
}

.mat-stepper-vertical.yellow-icon .mat-step-header .mat-step-icon-selected, 
.mat-stepper-vertical.yellow-icon .mat-step-header .mat-step-icon-state-edit {
    background-color: $color-yellow;
}

.mat-dialog-container h3.mat-title {
    margin: 0px;
}

.mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    width: 162px;
}

.submit-checkboxes .mat-icon {
    width: 16px;
    height: 16px;
    font-size: 16px;
    margin: 0px 6px;
    cursor: pointer;
}

/* BREAKPOINTS */
@include max-width(599px) {
    .bottom-tabbar-button p.mat-caption {
        display: none;
    }
    .mat-dialog-container {
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: auto !important;
        max-height: 90% !important;
        border-radius: 0px !important;
    }

    .speech-bubble {
        padding: 6px 10px;
    }
    
    .speech-bubble .bea-talks { 
        color: white;
        font-size: 18px;
    }

    .mat-card {
        min-width: 320px !important;
    }

    .bea.smartphone,
    .bea.presenting {
        min-width: 45%;
    }

}

@include min-width(600px) {
    .save-button-area {
        bottom: 64px;
    }
    
    .bottom-tabbar-button {
        min-width: 120px !important;
    }
    
    .bottom-tabbar-button p.mat-caption {
        display: block;
        margin-bottom: 0px;
        margin-top: 0px;
    }
}

@include min-width(1280px) {
    .container {
        width: 800px;
        margin: 0px auto;
    }

    .top-toolbar-row,
    .bottom-tabbar-row {
        width: 800px;
        margin: 0px auto;
    }
}
