@import "./theme";

@mixin max-width($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin min-width($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

.card-boxes {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 25px;
}

.card-boxes.small {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.card-boxes.big {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

@include max-width(599px) {
  .card-boxes.big {
    grid-template-columns: auto;
    grid-auto-rows: auto;
  }
}

.card-box {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  box-shadow: none !important;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background-color: #fbfbfb;
  height: 100%;
}

.card-box .content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.card-box .content .remove-file-button {
  position: absolute;
  top: -5px;
  right: -5px;
}

.card-box h1 {
  font-weight: 100;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 15px;
}

.card-box.clickable {
  cursor: pointer;
}

.card-box.center {
  text-align: center;
}

.card-box.padding {
  padding: 10px;
}

.card-box.centericon .content {
  display: flex;
  flex-direction: column;
  place-content: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
}

.card-box .ribbon-45 {
  position: absolute;
  top: 20px;
  right: -55px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #d32f2f;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 10px;
  color: white;
}

.card-box .ribbon-45:hover {
  filter: opacity(0.1);
  cursor: default;
}

.card-box .ribbon {
  position: absolute;
  top: 40px;
  right: -40px;
  background-color: $warn;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px dashed white;
}

.card-box.clickable:hover {
  background-color: rgb(245, 245, 245);
}

.card-box.centericon .content mat-icon {
  font-size: 80px;
  width: 80px;
  height: 80px;
}

.card-boxes.big .card-box.centericon .content mat-icon {
  font-size: 120px;
  width: 120px;
  height: 120px;
  padding: 30px;
}

.card-box .action-button mat-icon {
  color: white;
}

.card-box .actions {
  flex-direction: row;
  bottom: 0px;
  position: relative;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
}

.card-box .actions button.mat-stroked-button {
  border-radius: 0px;
  flex-grow: 1;
  border-bottom: none;
  border-left: none;
  padding: 5px;

  .mat-button-wrapper {
    flex-direction: column;
    display: flex;
    align-items: center;
    font-size: 11px;
    flex: auto;
    font-variant: all-petite-caps;
    line-height: 14px;
  }

  .mat-button-wrapper > .mat-icon:not(:only-child) {
    padding: 0px;
    margin: 0px;
  }
}

.card-box .actions > button .mat-icon {
  height: 18px;
  width: 18px;
  line-height: 1px;
  font-size: 24px;
}

.card-box .actions > spinner-button,
.card-box .actions > progress-bar-button {
  flex-grow: 1;
}

.card-box .actions > spinner-button > button,
.card-box .actions > progress-bar-button > button {
  border-radius: 0px;
  border-bottom: none;
  border-left: none;
  width: 100%;
}

.card-box .actions > button:last-child,
.card-box .actions > spinner-button:last-child > button,
.card-box .actions > progress-bar-button:last-child > button {
  border-right: none;
  border-radius: 0px;
}

.card-box mat-card-content {
  margin-top: 20px;
}

.card-box mat-card-title {
  font-size: 16px;
  text-align: center;
}

@include max-width(1919px) {
  //.card-box {
  //  width: 25vw;
  //}
}

@include max-width(1279px) {
  //.card-box {
  //  flex-basis: 33vw;
  //}
}

@include min-width(960px) {
  // .card-box {
  //  flex-basis: 50vw;
  // }
}

@include max-width(599px) {
  .card-box {
    margin-right: 0px;
    margin-left: 0px;
    max-width: unset;
  }
}

@include max-width(341px) {
//   .card-boxes {
//     display: flex;
//   }
}
